<template>
  <div>
    <div id="main_blank">
      <div id="blanc_title_name">
        Интернет-зоомагазин zoo-baza.com.ua
      </div>
      <div id="blanc_title_numbers">
        0986262460, 0994507509, 0937616006
      </div>
      <br clear="both">
      <div id="blanc_head">
        <div id="order_number_label">
          <b>№ заказа</b>
        </div>
        <div id="order_number">
          {{ order.orderNumber }}
        </div>
        <div id="order_date_label">
          <b>Дата</b>
        </div>
        <div id="order_date">
          {{ getDeliveryDateUnit(order.deliveryDates[0].date, 'day') }}
        </div>
        <div id="order_month">
          {{ getDeliveryDateUnit(order.deliveryDates[0].date, 'month') }}
        </div>
        <div id="order_year">
          {{ getDeliveryDateUnit(order.deliveryDates[0].date, 'year') }}
        </div>
        <div id="order_time_label">
          <b>время доставки</b>
        </div>
        <div id="order_time" style="">
          {{ order.deliveryDates[0].note }}
        </div>
        <br clear="left">
      </div>
      <div id="blanc_head_2">
        <div id="order_adress_label">
          <b>адрес</b>
        </div>
        <div id="order_adress">
          {{ order.forms[0].town ? order.forms[0].town + ', ' : '' }} {{ order.forms[0].address }}
        </div>
        <div id="order_phone_label">
          <b>телефон</b>
        </div>
        <div id="order_phone">
          {{ order.forms[0].phone }}
        </div>
        <br clear="left">
      </div>
      <div id="blanc_head_3">
        <div id="order_clientname_label">
          <b>ФИО клиента</b>
        </div>
        <div id="order_clientname">
          {{ order.forms[0].name }}
        </div>
        <div id="order_clienttype_label">
          <b>Тип клиента</b>
        </div>
        <div id="order_clienttype">
          {{ getRole(order) }}
        </div>
        <br clear="left">
      </div>
      <table class="order_table">
        <tbody>
        <tr>
          <th id="order_items_number">№</th>
          <th id="order_items_article">Артикул</th>
          <th id="order_items_name">Наименование</th>
          <th id="order_items_packings">Фасовка</th>
          <th id="order_items_quantity">К-во</th>
          <th id="order_items_single_amount">Цена</th>
          <th id="order_items_amount">Сумма</th>
        </tr>
        <tr v-for="n in 12">
          <td>
            {{ n }}
          </td>
          <td>
            {{ getOrderPosition(order, n).article }}
          </td>
          <td>
            {{ getOrderPosition(order, n).title }}
          </td>
          <td>
            {{ getOrderPosition(order, n).priceTagTitle }}
          </td>
          <td>
            {{ getOrderPosition(order, n).quantity }}
          </td>
          <td>
            {{ getOrderPosition(order, n).price ? getRoundValue(getOrderPosition(order, n).price) : '' }}
          </td>
          <td>
            {{ getOrderPosition(order, n).quantity ? getRoundValue(getOrderPosition(order, n).quantity * getOrderPosition(order, n).price) : '' }}
          </td>
        </tr>
        </tbody>
      </table>
      <table class="order_table order_footer">
        <tbody>
        <tr>
          <td class="col_long"><b>Общая сумма, грн.</b></td>
          <td class="col_short">{{ getRoundValue(order.total) }}</td>
        </tr>
        <tr>
          <td class="col_long"><b>Сумма скидки</b></td>
          <td>{{ getRoundValue(order.discount) }}</td>
        </tr>
        <tr>
          <td class="col_long"><b>Стоимость доставки</b></td>
          <td>{{ getRoundValue(order.delivery) }}</td>
        </tr>
        <tr>
          <td class="col_long"><b>Итого к оплате</b></td>
          <td>{{ getRoundValue(order.price) }}</td>
        </tr>
        <tr>
          <td class="col_long"><b>Скидка на следующую покупку</b></td>
          <td>0</td>
        </tr>
        </tbody>
      </table>
      <div id="last_row">
        <div id="order_sales_label">
          <b>метод оплаты</b>
        </div>
        <div id="order_sales">
          {{ order.paymentMethods[0] ? order.paymentMethods[0].title  : '' }} &nbsp;
        </div>
        <br clear="left">
        <div id="order_note_label">
          <b>примечание</b>
        </div>
        <div id="order_note">
          {{ order.forms[0].reason }}
        </div>
        <br clear="left">
      </div>
      <div class="end_description">
        Приятных и полезных Вам покупок в нашем интернет-магазине!
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment";

  export default {
    name: 'ZooBaza',
    props: ['order'],
    methods: {
      getRole(order) {
        if (order.role === 'guest') {
          return 'Новый покупатель';
        }
        if (order.role === 'customer') {
          return 'Постоянынй покупатель';
        }
        if (order.role === 'farmer') {
          return 'Питомник';
        }
        return "&nbsp;&nbsp;";
      },
      getRoundValue(value) {
        return Math.round(value * 100) / 100;
      },
      getOrderPosition(order, positionIndex) {
        const index = positionIndex - 1;
        if (order.positions && order.positions[index]) {
          return order.positions[index];
        }
        return {
          article: null,
          title: null,
          priceTagTitle: null,
          quantity: null,
          price: null,
          discount: null,
        }
      },
      getDeliveryDateUnit(date, unit){
        if (date) {
          if (unit === 'day') {
            return moment(date).format('DD');
          }
          if (unit === 'month') {
            const month = parseInt(moment(date).format('M'));
            switch (month) {
              case 1:
                return 'Январь';
              case 2:
                return 'Февраль';
              case 3:
                return 'Март';
              case 4:
                return 'Апрель';
              case 5:
                return 'Май';
              case 6:
                return 'Июнь';
              case 7:
                return 'Июль';
              case 8:
                return 'Август';
              case 9:
                return 'Сентябрь';
              case 10:
                return 'Октябрь';
              case 11:
                return 'Ноябрь';
              case 12:
                return 'Декабрь';
            }
          }
          if (unit === 'year') {
            return moment(date).format('YYYY');
          }
        }
        return '';
      }
    },
  }
</script>

<style lang="scss">

  /* Order details */
  #main_blank {
    width: 1000px;
    background-color: #FFFFFF;
    margin: auto;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
  }

  #main_blank input, #main_blank select {
    padding: 1px;
    height: 16px;
    margin: 0px;
  }

  .order_table {
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-collapse: collapse;
  }

  .order_table td {
    border: 1px solid black;
    text-align: center;
  }

  .order_table th {
    border: 2px solid black;
    text-align: center;
  }

  /* Top blanc */
  #blanc_title_name{width:300px;float:left;}
  #blanc_title_numbers{width:250px;float:right;text-align:right;}

  /* Blanc head */
  #blanc_head{border:2px solid black;}
  /* Order number */
  #blanc_head #order_number_label{border-right:1px solid black;width:120px;text-align:center;float:left;}
  #blanc_head #order_number{border-right:1px solid black;width:120px;float:left;text-align:center;}
  #blanc_head #order_number input{width:116px;margin:0px;height:16px;}
  /* Order date */
  #blanc_head #order_date_label{border-right:1px solid black;width:120px;float:left;text-align:center;}
  #blanc_head #order_date{border-right:1px solid black;width:100px;float:left;text-align:center;}
  #blanc_head #order_date input{width:96px;margin:0px;height:16px;}
  /* Order month */
  #blanc_head #order_month{border-right:1px solid black;width:140px;float:left;text-align:center;}
  #blanc_head #order_month select{width:136px;margin:0px;height:20px;}
  /* Order year */
  #blanc_head #order_year{border-right:1px solid black;width:100px;float:left;text-align:center;}
  #blanc_head #order_year input{width:96px;}
  /* Order time */
  #blanc_head #order_time_label{border-right:1px solid black;width:145px;float:left;text-align:center;}
  #blanc_head #order_time{width:140px;float:left;text-align:center;}
  #blanc_head #order_time input{width:141px;}
  /* Order row2 */
  #blanc_head_2{border:2px solid black;border-top:none;}
  /* Order adress */
  #blanc_head_2 #order_adress_label{border-right:1px solid black;width:100px;float:left; text-align:center;}
  #blanc_head_2 #order_adress{float:left;text-align:center;width:604px;border-right:1px solid black;}
  #blanc_head_2 #order_adress input{width:600px;}
  /* Order phone */
  #blanc_head_2 #order_phone_label{border-right:1px solid black;width:120px;float:left;text-align:center;}
  #blanc_head_2 #order_phone{width:169px;float:left;text-align:center;}
  #blanc_head_2 #order_phone input{width:167px;}

  /* Order row3 */
  #blanc_head_3{border-left:2px solid black;border-right:2px solid black;}
  /* Order distric */
  #blanc_head_3 #order_district_label{border-right:1px solid black;width:100px;float:left;text-align:center;}
  #blanc_head_3 #order_district{border-right:1px solid black;width:280px;float:left;}
  #blanc_head_3 #order_district input{width:276px;}
  /* Order client */
  #blanc_head_3 #order_clientname_label{border-right:1px solid black;width:100px;float:left;text-align:center;}
  #blanc_head_3 #order_clientname{float:left;text-align:center;width:604px;border-right:1px solid black;height:20px;}
  #blanc_head_3 #order_clientname input{width:600px;}
  /* Order client type */
  #blanc_head_3 #order_clienttype_label{border-right:1px solid black;width:120px;float:left;text-align:center;}
  #blanc_head_3 #order_clienttype{width:169px;float:left;text-align:center;}
  #blanc_head_3 #order_clienttype select{width:167px;height:19px;}

  /* Items part */
  #order_items_number{width:55px;}
  #order_items_article{width:160px;}
  #order_items_name{width:380px;}
  #order_items_packings{width:190px;}
  #order_items_quantity{width:55px;}
  #order_items_single_amount{width:80px}
  #order_items_amount{width:80px;}

  .order_article_column{width:155px;margin:0px;height:16px;}
  .order_name_column{width:375px;margin:0px;height:16px;}
  .order_packing_column{width:185px;margin:0px;height:16px;}
  .order_number_column{width:50px;margin:0px;height:16px;}
  .order_items_single_amount{width:75px;margin:0px;height:16px;}
  .order_amount_column{width:75px;margin:0px;height:16px;}

  /* Footer part */
  .order_footer .col_long{width:875px;text-align:right;padding-right:10px;}
  .order_footer .col_long input{width:119px;}
  .order_footer .col_short{width:128px;}
  /* Last row */
  #last_row{border:2px solid black;}
  /* Order sales */
  #order_sales_label{border-right:1px solid black;border-bottom:1px solid black;width:120px;text-align:center;float:left;}
  #order_sales{border-bottom:1px solid black;width:876px;height:21px;float:left;text-align:left;padding-left:10px;}
  #order_sales input{width:859px;text-align:left;}
  /* Order note */
  #order_note_label{border-right:1px solid black;width:120px;text-align:center;float:left;}
  #order_note{width:875px;height:20px;float:left;text-align:left;padding-left:10px;}
  #order_note input{width:859px;text-align:left;}
  .end_description{text-align: center}

  .end_description {
    text-align: center
  }

  @media print {
    @page {
	    size: A5 landscape;
    }

    .pagebreak {
      clear: both;
      page-break-after: always;
    }

    /* Cells dimensions */
    #main_blank{
      width:138mm !important;
      margin-top:3mm;
      font-family: Tahoma;
      font-size: 9px !important;
    }

    /* Top blanc */
    #blanc_title_name{width:55mm;float:left !important;}
    #blanc_title_numbers{width:60mm;float:right !important;}


    /* Order number */
    #blanc_head #order_number_label{width:19mm !important;}
    #blanc_head #order_number{width:18mm !important;}
    /* Order date */
    #blanc_head #order_date_label{width:15mm !important;}
    #blanc_head #order_date{width:8mm !important;}
    /* Order month */
    #blanc_head #order_month{width:15mm !important;}
    /* Order year */
    #blanc_head #order_year{width:8mm !important;}
    /* Order time */
    #blanc_head #order_time_label{width:25mm !important;}
    #blanc_head #order_time{width:27mm !important;}
    /* Order adress */
    #blanc_head_2 #order_adress_label{width:19mm !important;}
    #blanc_head_2 #order_adress{width:65mm !important; }
    /* Order phone */
    #blanc_head_2 #order_phone_label{width:15mm !important;border-left:1px solid black; border-right:1px solid black;}
    #blanc_head_2 #order_phone{width:37mm !important;}
    /* Order distric */
    #blanc_head_3 #order_district_label{width:15mm !important;}
    #blanc_head_3 #order_district{width:31mm !important;}
    /* Order client */
    #blanc_head_3 #order_clientname_label{width:19mm !important;}
    #blanc_head_3 #order_clientname{width:65mm !important;height:3mm !important;}
    /* Order client type */
    #blanc_head_3 #order_clienttype_label{width:18mm !important;border-left:1px solid black; border-right:1px solid black;fonx-size:12px;}
    #blanc_head_3 #order_clienttype{width:34mm !important;}

    /* Items part */
    #order_items_number{width:4mm !important;}
    #order_items_article{width:16mm !important;}
    #order_items_name{width:70mm !important;}
    #order_items_packings{width:26mm !important;}
    #order_items_quantity{width:7mm !important;}
    #order_items_single_amount{width:11mm !important}
    #order_items_amount{width:11mm !important;}

    /* Footer part */
    .col_long{width:124mm !important;}
    .col_short{width:17mm !important;}
    /* Order sales */
    #order_sales_label{width:19mm !important; height:4mm !important; padding-top: 0.2mm;}
    #order_sales{width: 117.9mm !important; height:4mm !important; padding-top: 0.2mm;}
    /* Order note */
    #order_note_label{width:19mm !important; height:4mm !important; padding-top: 0.2mm;}
    #order_note{width: 117.9mm !important; height:4mm !important; padding-top: 0.2mm;}
    .end_description{text-align: center}
  }
</style>
